@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('assets/fonts/OpenSans-Bold.woff') format('woff'),
        url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('assets/fonts/OpenSans-Italic.woff') format('woff'),
        url('assets/fonts/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}