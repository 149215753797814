/* You can add global styles to this file, and also import other style files */
* {
  --default-line-height: 1.2;
  --color-text: var(--syn-color-neutral-800);
}

:root {
  font-size: var(--syn-font-size-small);
  font-family: var(--syn-font-sans);
  line-height: var(--default-line-height);
  color: var(--color-text);
}

body {
  margin: 0;
}

h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  margin-block-end: 16px;
  margin-block-start: 24px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.full-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.col-gap-1 {
  column-gap: 1rem;
}

.margin-1 {
  margin: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-top-1 {
  margin-top: 1.06rem;
}

.margin-block-1 {
  margin-block: 1rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-end-1 {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.td-center {
  width: 1%;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
}
